<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="'modalUpdateAccount_' + account.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cập nhật Account</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body container">
          <form class="text-start row" @submit.prevent="updateAccount">
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Id</label>
              <input type="text" disabled :value="account.id" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">User Id</label>
              <input type="text" disabled :value="account.user_id" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Username</label>
              <input type="text" disabled :value="account.username" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Fb Id</label>
              <input type="text" disabled :value="account.fb_id" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Status</label>
              <select v-model="infos.status" class="form-select">
                <option value="0">0 (Hủy kích hoạt)</option>
                <option value="1">1 (Kích hoạt)</option>
              </select>
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Fb Name</label>
              <input type="text" v-model="infos.facebook_name" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Friend</label>
              <input type="text" v-model="infos.friend" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Cookie</label>
              <input type="text" v-model="infos.cookie" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Use_agent</label>
              <input type="text" v-model="infos.use_agent" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Notes</label>
              <input type="text" v-model="infos.notes" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Password</label>
              <input type="text" v-model="password" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Email</label>
              <input type="email" v-model="infos.email" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Code2fa</label>
              <input type="text" v-model="infos.code2fa" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Gender</label>
              <input type="text" v-model="infos.gender" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Locale</label>
              <input type="text" v-model="infos.locale" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Follow</label>
              <input type="number" v-model="infos.follow" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Birthday</label>
              <input type="date" v-model="birthday" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Location</label>
              <input type="text" v-model="infos.location" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Hometown</label>
              <input type="text" v-model="infos.hometown" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Following</label>
              <input type="number" v-model="infos.following" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Proxy type</label>
              <input type="text" v-model="infos.proxy_type" class="form-control" />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label for="" class="form-label">Proxy</label>
              <input type="text" v-model="infos.proxy" class="form-control" />
            </div>
            <div class="col-12 mt-2">
              <button class="btn btn-success py-1 w-25">Xác nhận</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accountApi from "@/api/account";
import moment from "moment";

export default {
  name: "UpdateAccount",
  props: {
    account: Object,
  },
  data() {
    return {
      infos: { ...this.account },
      password: null,
      birthday: null,
    };
  },
  watch: {
    account(value) {
      this.infos = { ...value };
    },
    infos: {
      handler(newData) {
        const { birthday } = newData;
        if (birthday) {
          this.birthday = moment(birthday).format("YYYY-MM-DD");
        }
      },
      deep: true, // deep watch
    },
    birthday(value) {
      this.infos.birthday = new Date(value);
    },
  },
  created() {
    this.birthday = moment(this.infos.birthday).format("YYYY-MM-DD");
  },
  methods: {
    async updateAccount() {
      const response = await accountApi.update(this.infos);
      if (response.success) {
        this.$toastr.success(response.message);
        this.$emit("updateAccount", this.infos);
      } else {
        this.$toastr.error(response.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 80% !important;
}
</style>

import { requestApi } from "./index";

const account = {
  list: async (params = "") => requestApi("/admin2021/facebook/account" + params),
  update: async (params) => requestApi("/admin2021/facebook/account/update", params),
  instagram: {
    list: async (params = "") => requestApi("/admin2021/instagram/account" + params),
    update: async (params) => requestApi("/admin2021/instagram/account/update", params),
  },
};

export default account;
